import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

import pic01 from '../assets/images/adblue/1.jpeg'
import pic02 from '../assets/images/adblue/2.jpeg'
import pic03 from '../assets/images/adblue/3.jpeg'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

const Adblue = (props) => (
    <Layout>
        <Helmet>
            <title>Adblue Tanks</title>
            <meta name="description" content="Landing Page" />
        </Helmet>

        {/* <BannerLanding /> */}

        <div id="main">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h2>Underground Tanks</h2>
                    </header>
                </div>
            </section>

            <Slider {...settings}>
                <div className="image">
                    <img src={pic02} alt="" />
                </div>
                <div className="image">
                    <img src={pic03} alt="" />
                </div>
                <div className="image">
                    <img src={pic01} alt="" />
                </div>
            </Slider>

            <section id="two" className="spotlights">
                <section>
                    {/* <div className="image">
                        <img src={pic01} alt="" />
                    </div> */}
                    <div className="content">
                        <div className="inner">
                            <p>All below ground double skinned storage tanks supplied by the Marcon Tanks are constructed from steel to a design that meets the requirements for environmental guidance and legislation. Below ground double skinned tanks are also suitable for storing petrol, diesel, gas oil and other associated oil products.<br /><br />

                            Adblue Storage Tanks and Adblue Dispensing Tanks from Marcon Tanks. We supply Adblue Tanks from 100 Litre Portable Tanks to 200,000 Litres. These Plastic Bunded Adblue Tanks are complete with Pumping Equipment. We also supply a range of pumps to fit Adblue IBC's and Ablue Container, Adblue Nozzles, Adblue Hoses and Adblue Flow Meters.</p> 
                        </div>    
                        </div>          
                       
                </section>
            </section>
        </div>

    </Layout>
)

export default Adblue